// .search-wrapper {
//   .scrolled-wrapper {
//     height: 460px;
//     overflow-y: auto;
//   }
//   .autocomplete-wrapper {
//     margin-left: 8px;
//   }
//   .pagination {
//     display: inline-block;
//     width: 300px;
//   }
// }

.page-step-select-wrapper {
  position: relative;
  margin: 20px 200px -60px;
  .page-step-label {
    position: absolute;
    display: block;
    top: -20px;
    right: 12px;
    font-size: 12px;
  }
  .page-step-select {
    font-size: 14px;
    width: 90px;
    padding: 2px;
  }
}
