.stations-filterer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-radius: 8px;


  .input-field {
    margin: 0;
    height: auto;

    input {
      font-size: 16px;
    }
  }

  .sations-list {
    min-width: 200px;
    padding: 0;
    flex: 1;
  }

  .isAlive-select {
    min-width: 150px;

    .isAlive-select__control {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 4px;
    }
  }
}